import { ActionManager, AbstractMesh, ExecuteCodeAction, Mesh } from '@babylonjs/core';
import dioramas_data from '@/utils/dioramas_data';
import { bus } from '@/main';

function moveInDiorama(triggerMesh: AbstractMesh) {
    const diorama = dioramas_data.find(el => el.triggerMeshName === triggerMesh.name);

    if (diorama) {
        bus.$emit('EVENT_moveInDiorama', diorama.id);
    }
}

function moveOutDiorama(triggerMesh: AbstractMesh) {
    const diorama = dioramas_data.find(el => el.triggerMeshName === triggerMesh.name);

    if (diorama) {
        bus.$emit('EVENT_moveOutDiorama', diorama.id);
    }
}

export function addTriggerWindowBehavior(_mesh: AbstractMesh, _playerMesh: Mesh) {
    // console.log('Add trigger window behavior');

    _mesh.isPickable = true;
    _mesh.enablePointerMoveEvents = true;
    _mesh.actionManager = new ActionManager(_mesh.getScene());

    _mesh.actionManager.registerAction(
        new ExecuteCodeAction(
            {
                trigger: ActionManager.OnIntersectionEnterTrigger,
                parameter: { mesh: _playerMesh, usePreciseIntersection: true },
            },
            function() {
                moveInDiorama(_mesh);
            }
        )
    );
    _mesh.actionManager.registerAction(
        new ExecuteCodeAction(
            {
                trigger: ActionManager.OnIntersectionExitTrigger,
                parameter: { mesh: _playerMesh, usePreciseIntersection: true },
            },
            function() {
                moveOutDiorama(_mesh);
            }
        )
    );
}
