import { AbstractMesh, ActionManager, ExecuteCodeAction } from '@babylonjs/core';

import { bus } from '../../main';

export function addPickableBehavior(_mesh: AbstractMesh) {
    _mesh.isPickable = true;
    _mesh.enablePointerMoveEvents = true;
    _mesh.actionManager = new ActionManager(_mesh.getScene());

    _mesh.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPickDownTrigger, () => {
            // When the specified mesh is clicked => disable it and and tell the overlay to appear
            bus.$emit('EVENT_showProduct', _mesh.name);
            _mesh.setEnabled(false);
        })
    );
}

//TO DO : add remove behavior
