import {AssetContainer, Color3, CubeTexture, Vector3} from "@babylonjs/core";
import {Mesh} from "@babylonjs/core/Meshes/mesh";


export default class DioramaData
{
    public container!: AssetContainer;
    public fogStartDistance!: number;
    public fogEndDistance!: number;
    public fogColor!: Color3;
    public environment!: CubeTexture;

    public initFunc!: Function;
    public impostor!: Mesh;

    public sunSettings!: {
        color: Color3;
        direction: Vector3;
        intensity: number;
    }
}
