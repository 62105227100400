









import { Component, Prop, Vue } from 'vue-property-decorator';
import ProductDetailsScene from '@/BabylonApp/Scenes/ProductDetailsScene';
import ProductDetailsPanel from './ProductDetailsPanel.vue';
import { allproductsJsonData } from '@/utils/products_data';
import { bus } from '../main';

@Component({
    components: {
        ProductDetailsPanel,
    },
})
export default class ProductDetails extends Vue {
    @Prop({ default: '' })
    selectedProduct!: string;

    public game!: ProductDetailsScene;
    private productId = -1;

    created() {
        this.productId = this.productSceneNameToProductId(this.selectedProduct);
        if (this.productId < 1) {
            console.error(`Did not find any product corresponding to the selected product (${this.selectedProduct}).`);
        }
    }

    mounted(): void {
        console.log('JSON PRD : ', allproductsJsonData);

        const canvas = document.getElementById('productCanvas') as HTMLCanvasElement;
        // const meshToSpawn = this.productNumberToMeshName(this.productId);
        const product = allproductsJsonData.find(prd => prd.product_number === this.productId);
        if (!product) {
            console.warn(`Didn't find selected product`);
            return;
        }
        this.game = new ProductDetailsScene(canvas, product);
        this.game.init();
    }

    // TODO : destroy scene when panel closed

    closeOverlay(): void {
        bus.$emit('EVENT_closeProductOverlay');
    }
    productSceneNameToProductId(productSceneName: string) {
        // Return the maserati product number (mpn) based on the name the player clicked on
        const res = allproductsJsonData.find(prd => prd.mesh_scene_name === productSceneName);
        if (res) {
            return res.product_number;
        } else return -1;
    }
}
