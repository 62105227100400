import { Axis, FreeCamera, Mesh, MeshBuilder, Scene, Vector3 } from '@babylonjs/core';

/**
 * Player Controller
 */
export default class PlayerController {
    readonly scene: Scene;

    readonly camera: FreeCamera;
    readonly colliderMesh: Mesh;
    get playerCamera() {
        return this.camera;
    }
    get playerColliderMesh() {
        return this.colliderMesh;
    }

    public constructor(_scene: Scene) {
        this.scene = _scene;

        this.camera = new FreeCamera('PlayerCamera', new Vector3(1, 1.5, 0), this.scene);
        this.camera.minZ = 0.1;
        this.camera.maxZ = 3000.0;

        this.camera.checkCollisions = true;
        this.camera.applyGravity = true;
        this.camera.speed = 0.08;
        this.camera.ellipsoid = new Vector3(0.2, 0.75, 0.2);

        this.camera.keysUp.push(90);
        this.camera.keysDown.push(83);
        this.camera.keysLeft.push(81);
        this.camera.keysRight.push(68);

        // Collider mesh used to trigger interacitons in the scene
        this.colliderMesh = MeshBuilder.CreateSphere('playerColliderMesh', { segments: 8, diameter: 0.001 }, _scene);
        _scene.registerBeforeRender(() => {
            // Not attaching the collider to the camera because we don't want it to rotate with the camera view
            this.colliderMesh.position = this.camera.globalPosition.add(new Vector3(0, -0.7, 0));
            // this.colliderMesh.rotateAround(this.camera.position, Axis.Y, this.camera.rotation.y);
        });
        this.colliderMesh.isVisible = false;

        // this.attachControls();
    }

    public attachControls() {
        this.camera.attachControl();
    }

    public detachControls() {
        this.camera.detachControl();
    }
}
