import { ArcRotateCamera, Color4, HemisphericLight, MeshBuilder, Scene, SceneLoader, Vector3 } from '@babylonjs/core';
import { Engine } from '@babylonjs/core/Engines/engine';
import LoadingScreenProductPanel from '@/components/LoadingScreenProductPanel';
import Product from '../Novelab/Product';

export default class ProductDetailsScene {
    readonly canvas: HTMLCanvasElement;
    readonly engine: Engine;

    // Scenes
    public scene: Scene;
    private product: Product;

    public constructor(canvas: HTMLCanvasElement, _product: Product) {
        this.canvas = canvas;
        this.product = _product;
        this.engine = new Engine(this.canvas, true);
        this.engine.loadingScreen = new LoadingScreenProductPanel();

        this.scene = this.createScene(this.engine, this.canvas, this.product);
        // console.log('Creating a scene with mesh  : ', meshName);

        this.engine.displayLoadingUI();
        SceneLoader.ImportMeshAsync('', './assets/models/', this.product.mesh_file_name, this.scene).then(() => {
            // Product mesh loaded
            this.scene.meshes.forEach((mesh) => {
                mesh.rotation.y = -90;
            });

            this.engine.hideLoadingUI();
        });
    }

    public init(): void {
        this.engine.runRenderLoop(() => {
            this.scene.render();
        });

        window.addEventListener('resize', () => {
            this.engine.resize();
        });

        window.addEventListener('orientationchange', () => {
            this.engine.resize();
        });
    }

    private createScene = function(_engine: Engine, _canvas: HTMLCanvasElement, _product: Product) {
        const scene = new Scene(_engine);
        scene.clearColor = new Color4(0, 0, 0, 0);

        const camera = new ArcRotateCamera(
            'camera1',
            (_product.cam_base_rotation_alpha * Math.PI) / 180,
            (_product.cam_base_rotation_beta * Math.PI) / 180,
            _product.cam_base_distance,
            new Vector3(0, 0, 0),
            scene
        );

        camera.lowerRadiusLimit = 1.0;
        camera.upperRadiusLimit = 3.0;
        camera.wheelPrecision = 40;
        camera.attachControl(_canvas, true);
        camera.panningSensibility = 0; // disables RMB dragging.
        camera.minZ = 0.1;

        const light = new HemisphericLight('light', new Vector3(0, 1, 0), scene);
        light.intensity = _product.light_intensity;

        // const ground = MeshBuilder.CreateGround('ground', { width: 6, height: 6 }, scene);
        return scene;
    };
}
