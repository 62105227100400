import {
    DefaultRenderingPipeline,
    Engine,
    HemisphericLight,
    Mesh,
    PBRMaterial,
    Scene,
    SceneLoader,
    Vector3,
} from '@babylonjs/core';

import AbstractScene from '@/BabylonApp/Novelab/AbstractScene';
import PlayerController from '@/BabylonApp/PlayerController';
import levelsData from '@/utils/levels_data.json';
import {addTriggerDoorBehavior} from '@/BabylonApp/Behaviors/TriggerDoorBehavior';
import {Transform} from '@/BabylonApp/Novelab/TransformType';
import VirtualJoystickControl from '@/BabylonApp/Novelab/VirtualJoystickControl';

/**
 * Hall Scene
 *
 * The first room of the experience that is used to access all others
 * rooms of the virtual shop.
 */
export default class HallScene extends AbstractScene {
    private levelId = 1;
    private levelData = levelsData[this.levelId - 1];

    private m_sceneDirectoryPath = './assets/scenes/01_hallRoom/';
    private m_sceneFilePath = 'hallRoom.babylon';

    private m_player!: PlayerController;
    private renderPipeline!: DefaultRenderingPipeline;
    private joysticks!: VirtualJoystickControl;

    public init(_canvas: HTMLCanvasElement, _engine: Engine, _onSuccess: Function, _startTransform: Transform): void {
        SceneLoader.Load(this.m_sceneDirectoryPath, this.m_sceneFilePath, _engine, (scene: Scene) => {
            this.m_scene = scene;

            this.m_player = new PlayerController(this.m_scene);

            const spawnTransform = this.getSpawnTransform(_startTransform);
            this.m_player.camera.position = new Vector3(
                spawnTransform.position.x,
                spawnTransform.position.y,
                spawnTransform.position.z
            );
            this.m_player.camera.rotation = new Vector3(
                spawnTransform.rotation.x,
                spawnTransform.rotation.y,
                spawnTransform.rotation.z
            );

            // this.m_player.camera.attachControl();
            this.attachControlsBasedOnDevice();

            this.setupEnvironment();
            this.setupRenderPipeline();
            this.setupTriggers();

            this.enable();
            this.m_isReady = true;
            _onSuccess();
        });
    }

    public render(): void {
        this.m_scene.render();
    }

    public update(): void {}

    private attachControlsBasedOnDevice(): void {
        this.m_player.attachControls();
        this.joysticks = new VirtualJoystickControl(this.m_scene, this.m_player);
        this.joysticks.movementSpeed = 1.7;
        this.joysticks.rotationSpeed = 1.7;
    }

    private getSpawnTransform(_startTransform?: Transform): Transform {
        if (_startTransform) {
            //When the player come from another room
            return _startTransform;
        } else {
            // When the player spawn for the first time
            return {
                position: {x: 0, y: 2, z: 0},
                rotation: {x: 0, y: 0, z: 0},
            };
        }
    }

    private setupTriggers(): void {
        // Setup the triggers for the doors
        const triggerMeshes = this.levelData.doorsTriggerMeshes;
        if (!triggerMeshes) {
            console.log('No doors to setup trigger for');
            return;
        }
        triggerMeshes.forEach(trgMeshData => {
            const trgMesh = this.m_scene.getMeshByName(trgMeshData.triggerMeshName) as Mesh;
            addTriggerDoorBehavior(trgMesh, this.m_player.playerColliderMesh, trgMeshData.id);
        });
    }

    private setupEnvironment(): void {
        // Lighting
        this.m_scene.environmentIntensity = 0;

        const ambiant = new HemisphericLight('ambiant', Vector3.Down(), this.m_scene);
        ambiant.intensity = 1.5;

        this.m_scene.materials.forEach(mat => {
            const pbr = mat as PBRMaterial;
            pbr.unlit = true;
        });
    }

    private setupRenderPipeline(): void {
        // Graphic Pipeline setup
        this.renderPipeline = new DefaultRenderingPipeline('defaultPipeline', false, this.m_scene, [this.m_player.camera]);

        // Fxaa (Anti Aliasing)
        this.renderPipeline.fxaaEnabled = true;
        this.renderPipeline.fxaa.samples = 4;

        // Bloom Post Process
        this.renderPipeline.bloomEnabled = true;
        this.renderPipeline.bloomThreshold = 0.9;
        this.renderPipeline.bloomWeight = 0.15;
        this.renderPipeline.bloomKernel = 64;
        this.renderPipeline.bloomScale = 0.5;

        // Tone Mapping
        this.renderPipeline.imageProcessing.toneMappingEnabled = true;
    }
}
