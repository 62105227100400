












import { Component, Vue } from 'vue-property-decorator';
import BabylonApp from './components/BabylonApp.vue';
import ProductDetails from './components/ProductDetails.vue';
import { bus } from './main';
@Component({
    components: {
        BabylonApp,
        ProductDetails,
    },
})
class App extends Vue {
    showProductDetails = false;
    selectedProduct = '';

    created() {
        bus.$on('EVENT_showProduct', (data: string) => {
            this.selectedProduct = data;
            this.showProductDetails = true;
        });
        bus.$on('EVENT_closeProductOverlay', () => {
            this.showProductDetails = !this.showProductDetails;
        });
    }
}
export default App;
