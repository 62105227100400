import {
    AbstractMesh,
    Behavior, Nullable,
    Observer,
    Scene,
    Space,
    Vector3
} from '@babylonjs/core';

/**
 * Example of Behavior.
 */
export default class ProductAnimationBehavior implements Behavior<AbstractMesh>
{
    public name!: string;

    private targetMesh!: AbstractMesh;
    private observable!: Nullable<Observer<Scene>>;
    private startY!: number;
    private totalTime!: number;

    public init(): void {
        this.name = "ProductAnimationBehavior";
    }

    public attach(target: AbstractMesh): void
    {
        this.totalTime = 0.0;
        this.targetMesh = target;
        this.startY = this.targetMesh.position.y;

        this.observable = target.getScene().onBeforeRenderObservable.add((scene) => {
            this.totalTime += scene.getEngine().getDeltaTime();

            const newPos = this.targetMesh.position;
            newPos.y = this.startY + Math.sin(this.totalTime / 1000.0) / 50.0;
            this.targetMesh.position = newPos;

            this.targetMesh.rotate(Vector3.Up(), 0.005, Space.WORLD);
        });
    }

    public detach(): void
    {
        this.targetMesh.getScene().onBeforeRenderObservable.remove(this.observable);
    }
}
