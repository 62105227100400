import { ILoadingScreen } from "@babylonjs/core";
import URLHelper from "@/utils/URL";

export default class LoadingScreen implements ILoadingScreen
{
    loadingUIBackgroundColor!: string;
    loadingUIText = "Please wait...";

    htmlElement: HTMLElement;

    public constructor()
    {
        // Main div that cover all the viewport
        this.htmlElement = document.createElement("div");
        this.htmlElement.id = "loading-screen";

        this.htmlElement.style.position = "absolute";
        this.htmlElement.style.zIndex = "100";
        this.htmlElement.style.top = "0";
        this.htmlElement.style.bottom = "0";
        this.htmlElement.style.left = "0";
        this.htmlElement.style.right = "0";

        this.htmlElement.style.display = "flex";
        this.htmlElement.style.flexDirection = "column";
        this.htmlElement.style.gap = "25px";
        this.htmlElement.style.justifyContent = "center";
        this.htmlElement.style.alignItems = "center";

        this.htmlElement.style.backgroundColor = "#152A4B";
        this.htmlElement.style.color = "#EEEEEE";
        this.htmlElement.style.fontFamily = "Arial, sans-serif";

        this.htmlElement.style.opacity = "1.0";
        this.htmlElement.style.transition = "opacity 0.5s";

        // Things to display
        const logo = document.createElement('img');
        logo.src = URLHelper.get("/assets/images/loading_logo.png");
        this.htmlElement.append(logo);

        const subTitle = document.createElement("p");
        subTitle.innerText = "Your experience is loading\n" +
            "Please wait...";
        subTitle.style.textAlign = "center";
        subTitle.style.lineHeight = "25px";
        this.htmlElement.append(subTitle);

        const icon = document.createElement('img');
        icon.src = URLHelper.get("/assets/images/loading_icon.svg");
        icon.style.marginTop = "100px";
        this.htmlElement.append(icon);

        this.htmlElement.style.display = "none";
        document.body.appendChild(this.htmlElement);
    }

    public displayLoadingUI(): void
    {
        this.htmlElement.style.display = "flex";
        this.htmlElement.style.opacity = "1.0";
    }

    public hideLoadingUI(): void
    {
        this.htmlElement.style.opacity = "0.0";
        setTimeout(() => {
            this.htmlElement.style.display = "none";
        }, 550);
    }
}
