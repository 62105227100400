import { AbstractMesh, Behavior, Nullable, Observer, Scene } from '@babylonjs/core';

export default class MC20LogoAnimationBehavior implements Behavior<AbstractMesh> {
    public name = 'MC20LogoAnimation';

    private targetMesh!: AbstractMesh;
    private observable!: Nullable<Observer<Scene>>;
    private startY!: number;
    private totalTime!: number;

    init(): void {
        //
    }

    attach(target: AbstractMesh): void {
        this.totalTime = 0.0;
        this.targetMesh = target;
        this.startY = this.targetMesh.position.y;

        this.observable = this.targetMesh.getScene().onBeforeRenderObservable.add(scene => {
            this.totalTime += scene.getEngine().getDeltaTime();

            const newPos = this.targetMesh.position;
            newPos.y = this.startY + Math.sin(this.totalTime / 1000.0) / 20.0;
            this.targetMesh.position = newPos;
        });
    }

    detach(): void {
        if (this.targetMesh) {
            this.targetMesh.getScene().onBeforeRenderObservable.remove(this.observable);
        }
    }
}
