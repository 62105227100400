import { ActionManager, AbstractMesh, ExecuteCodeAction, Mesh } from '@babylonjs/core';
import levelsData from '@/utils/levels_data.json';
import { bus } from '@/main';

export function addTriggerDoorBehavior(_mesh: AbstractMesh, _playerMesh: Mesh, _triggerId: string) {
    _mesh.isPickable = true;
    _mesh.enablePointerMoveEvents = true;
    _mesh.actionManager = new ActionManager(_mesh.getScene());

    _mesh.actionManager.registerAction(
        new ExecuteCodeAction(
            {
                trigger: ActionManager.OnIntersectionEnterTrigger,
                parameter: { mesh: _playerMesh, usePreciseIntersection: true },
            },
            function() {
                console.log('Move in door with id : ', _triggerId);
                bus.$emit('EVENT_doorTriggered', _triggerId);
            }
        )
    );
}
