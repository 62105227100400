import { AbstractMesh, Nullable, Observer, Scene } from '@babylonjs/core';

/**
 *
 */
export default class WindowFX {
    private mesh!: AbstractMesh;
    private timeToFade = 0.5 * 1000.0;

    private fadeInObserver!: Nullable<Observer<Scene>>;
    private fadeOutObserver!: Nullable<Observer<Scene>>;

    public get isVisible() {
        return this.mesh.isVisible;
    }

    public constructor(_mesh: AbstractMesh) {
        this.mesh = _mesh;
        this.mesh.visibility = 0.0;
        this.mesh.isVisible = false;
    }

    public fadeIn(_callback: Function): void {
        const scene = this.mesh.getScene();
        const engine = this.mesh.getEngine();
        let passedTime = 0.0;

        this.mesh.visibility = 0.0;
        this.mesh.isVisible = true;

        this.fadeInObserver = scene.onBeforeRenderObservable.add(() => {
            this.mesh.visibility = passedTime / this.timeToFade;
            passedTime += engine.getDeltaTime();

            if (passedTime >= this.timeToFade) {
                this.mesh.visibility = 1.0;
                scene.onBeforeRenderObservable.remove(this.fadeInObserver);
                _callback();
            }
        });
    }

    public fadeOut(_callback: Function): void {
        const scene = this.mesh.getScene();
        const engine = this.mesh.getEngine();
        let passedTime = 0.0;

        this.mesh.visibility = 1.0;

        this.fadeOutObserver = scene.onBeforeRenderObservable.add(() => {
            this.mesh.visibility = 1.0 - passedTime / this.timeToFade;
            passedTime += engine.getDeltaTime();

            if (passedTime >= this.timeToFade) {
                this.mesh.visibility = 0.0;
                this.mesh.isVisible = false;
                scene.onBeforeRenderObservable.remove(this.fadeOutObserver);
                _callback();
            }
        });
    }
}
