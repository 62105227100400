import axios from 'axios';

const LOCALS = 'en_gb';

export const baseURL = 'https://maserati-dev.7circles.com/api/';

const axiosInstance = axios.create({
    baseURL: baseURL,
    withCredentials: false,
});

export async function fetchProductById(productId: number) {
    const url = `products/search/mpn?mpn=${productId.toString()}&locales=${LOCALS}&projection=simple`;
    console.log('Fetching : ', url);

    return new Promise((onSuccess, onFail) => {
        // console.log(axiosInstance);

        axiosInstance
            .get(url)
            .then(response => {
                if (!response) {
                    return onFail(`No response`);
                }
                console.log('on success');
                onSuccess(response.data._embedded.products[0]);
            })
            .catch(error => {
                return onFail(`Response failure ${error}`);
            });
    });
}
