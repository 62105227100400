/**
 * A basic helper class that help to manage environments
 * easily by abstracting URL management.
 */
export default class URLHelper
{
    private static baseURL = process.env.NODE_ENV === 'production' ? '': '';

    /**
     * Generate the URL for the current environment.
     * Always put a start '/' in your paths.
     * @param _path
     */
    public static get(_path: string): string
    {
        return this.baseURL + _path;
    }
}
