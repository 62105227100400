import {AssetContainer, Color3, DirectionalLight, Material, Mesh, PBRMaterial, Scene, Vector3} from "@babylonjs/core";

export default [
    {
        id: 1,
        triggerMeshName: "Trigger_Castle",
        windowFx: "WindowsFx_01",
        windowMesh: "ENV_Window_01",
        fogStartDistance: 30,
        fogEndDistance: 150,
        fogColor: "#FFFFFF",
        carImpostor: "01_castle_car_impostor",
        sun: {
            color: new Color3(1,1,1),
            direction: new Vector3(-1, -1, -1),
            intensity: 5.0
        },
        skydomeName: "HDRISphere",
        gltfPath: "assets/scenes/02_mc20Room/Diorama/01_Castle/MC20_Castle.gltf",
        initFunc: function(container: AssetContainer, scene: Scene) {
            console.log("Init Castle");

            container.meshes.forEach(mesh => {
                if (mesh.material !== null) (mesh.material as PBRMaterial).unlit = true;
            });
        }
    },
    {
        id: 2,
        triggerMeshName: "Trigger_Desert",
        windowFx: "WindowsFx_02",
        windowMesh: "ENV_Window_02",
        fogStartDistance: 175,
        fogEndDistance: 1000,
        fogColor: "#D19D83",
        carImpostor: "02_desert_car_impostor",
        sun: {
            color: new Color3(79.0/255.0,34.0/255.0,13.0/255.0),
            direction: new Vector3(-27.0, -5.0, 14.0),
            intensity: 2.0
        },
        skydomeName: "Desert_Dome",
        gltfPath: "assets/scenes/02_mc20Room/Diorama/02_Desert/02_Desert.gltf",
        initFunc: function(container: AssetContainer, scene: Scene) {
            console.log("Init Desert");

            container.meshes.forEach(mesh => {
                if (mesh.material !== null) (mesh.material as PBRMaterial).unlit = true;
            });
        }
    },
    {
        id: 3,
        triggerMeshName: "Trigger_SaltDesert",
        windowFx: "WindowsFx_03",
        windowMesh: "ENV_Window_03",
        fogStartDistance: 30,
        fogEndDistance: 150,
        fogColor: "#FFFFFF",
        carImpostor: "03_salt_car_impostor",
        sun: {
            color: new Color3(1,1,1),
            direction: new Vector3(-1.0, -1.0, -0.5),
            intensity: 10.0
        },
        skydomeName: "Sphere",
        gltfPath: "assets/scenes/02_mc20Room/Diorama/03_Salt/03_Salt.gltf",
        initFunc: function(container: AssetContainer, scene: Scene) {
            console.log("Init Salt");

            container.meshes.forEach(mesh => {
                if (mesh.material !== null) (mesh.material as PBRMaterial).unlit = true;
            });
        }
    },
    {
        id: 4,
        triggerMeshName: "Trigger_Racetrack",
        windowFx: "WindowsFx_04",
        windowMesh: "ENV_Window_04",
        fogStartDistance: 115,
        fogEndDistance: 1500,
        fogColor: "#9CDBBA",
        carImpostor: "04_racetrack_car_impostor",
        sun: {
            color: new Color3(1,235.0/255.0,197.0/255.0),
            direction: new Vector3(-10,-1,-100),
            intensity: 12.0
        },
        skydomeName: "04_Racetrack_dome",
        gltfPath: "assets/scenes/02_mc20Room/Diorama/04_Racetrack/04_Racetrack.gltf",
        initFunc: function(container: AssetContainer, scene: Scene) {
            console.log("Init Racetrack");

            container.meshes.forEach(mesh => {
                if (mesh.material !== null) (mesh.material as PBRMaterial).unlit = true;
            });

            container.materials.forEach((mat: Material) => {
                if(mat.name === "Spruce_New_branch")
                {
                    const pbr = mat as PBRMaterial;
                    pbr.environmentIntensity = 0;
                    pbr.transparencyMode = 2;
                    pbr.useAlphaFromAlbedoTexture = true;
                    pbr.directIntensity = 0.5;
                    pbr.unlit = false;
                }
                else if(mat.name === "Spruce_New_bark")
                {
                    const pbr = mat as PBRMaterial;
                    pbr.environmentIntensity = 0;
                    pbr.directIntensity = 0.5;
                    pbr.unlit = false;
                }
                else if(mat.name === "Spruce_New_billboard")
                {
                    const pbr = mat as PBRMaterial;
                    pbr.environmentIntensity = 0;
                    pbr.directIntensity = 0.5;
                    pbr.unlit = false;
                }
            });

            const mc20Model = scene.getMeshByName("MC20_car_diorama") as Mesh;

            container.lights.forEach((light) => {
                light.setEnabled(false);
            })
        }
    }
]
