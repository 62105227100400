export function degToRad(deg: number): number {
    return (Math.PI * deg) / 180;
}

export function radToDeg(rad: number): number {
    return (rad * 180) / Math.PI;
}

export function clamp(num: number, min: number, max: number): number {
    return num <= min ? min : num >= max ? max : num;
}
