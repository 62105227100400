import {
    AbstractMesh,
    Color3,
    DefaultRenderingPipeline,
    Engine,
    PBRMaterial,
    Scene,
    SceneLoader,
    Vector3,
} from '@babylonjs/core';

import AbstractScene from '@/BabylonApp/Novelab/AbstractScene';
import PlayerController from '@/BabylonApp/PlayerController';
import levelsData from '@/utils/levels_data.json';
import {addTriggerDoorBehavior} from '@/BabylonApp/Behaviors/TriggerDoorBehavior';
import {Mesh} from '@babylonjs/core/Meshes/mesh';
import {Transform} from '@/BabylonApp/Novelab/TransformType';
import ProductAnimationBehavior from '@/BabylonApp/Behaviors/ProductAnimationBehavior';
import VirtualJoystickControl from '@/BabylonApp/Novelab/VirtualJoystickControl';
import {fragmentProductsJsonData} from '@/utils/products_data';

import {addPickableBehavior} from '../Behaviors/PickableProductBehavior';
import {bus} from '../../main';

/**
 * FragmentRoom
 *
 */
export default class FragmentScene extends AbstractScene {
    private levelId = 3;
    private levelData = levelsData[this.levelId - 1];

    private m_sceneDirectoryPath = './assets/scenes/03_FragmentRoom/';
    private m_sceneFilePath = 'FragmentRoom.babylon';
    private m_pickableMeshes: AbstractMesh[] = [];

    private m_player!: PlayerController;
    private renderPipeline!: DefaultRenderingPipeline;

    private joysticks!: VirtualJoystickControl;

    public init(_canvas: HTMLCanvasElement, _engine: Engine, _onSuccess: Function, _startTransform: Transform): void {
        SceneLoader.Load(this.m_sceneDirectoryPath, this.m_sceneFilePath, _engine, (scene: Scene) => {
            this.m_scene = scene;

            this.m_player = new PlayerController(this.m_scene);

            const spawnTransform = this.getSpawnTransform(_startTransform);
            // this.m_player.camera.position = new Vector3(
            //     spawnTransform.position.x,
            //     spawnTransform.position.y,
            //     spawnTransform.position.z
            // );
            // this.m_player.camera.rotation = new Vector3(
            //     spawnTransform.rotation.x,
            //     spawnTransform.rotation.y,
            //     spawnTransform.rotation.z
            // );

            // For the demo, force start position
            this.m_player.camera.position = new Vector3(0, 1.39, 0);

            this.m_player.camera.rotation = new Vector3(0, 1.57, 0);

            // this.m_player.camera.attachControl();
            this.attachControlsBasedOnDevice();

            this.setupEnvironment();
            this.setupRenderPipeline();
            this.setupMaterials();
            this.setupBehaviors();
            this.setupTriggers();

            this.enable();
            this.m_isReady = true;
            _onSuccess();
        });
    }

    public render(): void {
        this.m_scene.render();
    }

    private attachControlsBasedOnDevice(): void {
        this.m_player.attachControls();
        this.joysticks = new VirtualJoystickControl(this.m_scene, this.m_player);
        this.joysticks.movementSpeed = 2.0;
        this.joysticks.rotationSpeed = 2.0;
    }

    private getSpawnTransform(_startTransform?: Transform): Transform {
        if (_startTransform) {
            //When the player come from another room
            return _startTransform;
        } else {
            // When the player spawn for the first time
            return {
                position: {x: 0, y: 2, z: 0},
                rotation: {x: 0, y: 0, z: 0},
            };
        }
    }
    public update(): void {}

    private setupTriggers(): void {
        // DISABLE TRIGGER FOR THIS BUILD

        // Remove door mesh from scene
        this.m_scene.getMeshByName("street_rideauPortail")?.setEnabled(false);

        // Setup the triggers for the doors
        const triggerMeshes = this.levelData.doorsTriggerMeshes;
        if (!triggerMeshes) {
            console.log('No doors to setup trigger for');
            return;
        }
        triggerMeshes.forEach(trgMeshData => {
            const trgMesh = this.m_scene.getMeshByName(trgMeshData.triggerMeshName) as Mesh;
            addTriggerDoorBehavior(trgMesh, this.m_player.playerColliderMesh, trgMeshData.id);
        });
    }

    private setupRenderPipeline(): void {
        // Graphic Pipeline setup
        this.renderPipeline = new DefaultRenderingPipeline('defaultPipeline', false, this.m_scene, [this.m_player.camera]);

        // Fxaa (Anti Aliasing)
        this.renderPipeline.fxaaEnabled = true;
        this.renderPipeline.fxaa.samples = 4;

        // Bloom Post Process
        this.renderPipeline.bloomEnabled = true;
        this.renderPipeline.bloomThreshold = 0.9;
        this.renderPipeline.bloomWeight = 0.5;
        this.renderPipeline.bloomKernel = 64;
        this.renderPipeline.bloomScale = 0.5;

        // Tone Mapping
        this.renderPipeline.imageProcessing.toneMappingEnabled = true;
    }

    private setupMaterials(): void {
        const doorMaterial = this.m_scene.getMaterialByName('EmissionPorte') as PBRMaterial;
        doorMaterial.emissiveIntensity = 2.0;
        doorMaterial.emissiveColor = Color3.White();
    }

    private setupEnvironment(): void {
        this.m_scene.environmentIntensity = 0.1;

        this.m_scene.fogMode = 3;
        this.m_scene.fogStart = 0.0;
        this.m_scene.fogEnd = 60.0;
        this.m_scene.fogColor = new Color3(0.09, 0.09, 0.09);
    }

    private setupBehaviors(): void {
        this.m_scene.getMeshesByTags('Product', mesh => {
            const tmp = mesh as Mesh;
            tmp.addBehavior(new ProductAnimationBehavior());
        });

        // Re-enable all the hidden meshes (when product panel is closed)
        bus.$on('EVENT_closeProductOverlay', () => {
            this.m_pickableMeshes.forEach(mesh => {
                mesh.setEnabled(true);
            });
        });

        // find all the product meshes and add them the necessary behaviors
        fragmentProductsJsonData.forEach(prd => {
            const mesh = this.m_scene.getMeshByName(prd.mesh_scene_name);
            if (mesh) {
                this.m_pickableMeshes.push(mesh);
                addPickableBehavior(mesh);
            }
        });
    }
}
