import dioramas_data from '@/utils/dioramas_data';
import DioramaData from '@/BabylonApp/Scenes/mc20RoomScene/DioramaData';
import {Color3, CubeTexture, DirectionalLight, Mesh, PBRMaterial, Scene, SceneLoader, Vector3} from '@babylonjs/core';
import URLHelper from '@/utils/URL';
import { bus } from '../../../main';
import WindowFX from '@/BabylonApp/Scenes/mc20RoomScene/WindowFX';

export default class DioramaHandler {
    private scene: Scene;
    private dioramas: Array<DioramaData>;
    private desiredState: Array<number>;
    private currentState: Array<number>;
    private currentlyTransitioning: boolean;
    private windowFXs: WindowFX[] = [];

    public windowTriggers!: Array<Mesh>;

    // Mc20 car and impostors
    private m_mc20DioramaCar!: Mesh;

    // Dioramas sun
    private dioramaSun!: DirectionalLight;

    public constructor(_scene: Scene) {
        this.scene = _scene;

        this.desiredState = [0, 0, 0, 0, 0];
        this.currentState = [0, 0, 0, 0, 0];
        this.currentlyTransitioning = false;
        /*   0 : not loaded,   1 : loaded but not visibile,   2 : visible .
        Index 1 is diorama 1, 2 is diorama2... and index 0 is not used   */

        this.setupWindowFx();

        // Get the diorama car
        this.m_mc20DioramaCar = this.scene.getMeshByName("MC20_car_diorama") as Mesh;

        // Init sun
        this.dioramaSun = new DirectionalLight("dioramasSun", Vector3.Zero(), this.scene);
        this.dioramaSun.intensity = 0;

        // Instantiate an empty AssetContainer for each diorama
        this.dioramas = new Array<DioramaData>();
        dioramas_data.forEach(diorama => {
            const temp = new DioramaData();
            temp.fogStartDistance = diorama.fogStartDistance;
            temp.fogEndDistance = diorama.fogEndDistance;
            temp.fogColor = Color3.FromHexString(diorama.fogColor);
            temp.initFunc = diorama.initFunc;
            temp.impostor = this.scene.getMeshByName(diorama.carImpostor) as Mesh;
            temp.sunSettings = diorama.sun;
            this.dioramas[diorama.id] = temp;
        });

        bus.$on('EVENT_moveInDiorama', (_id: number) => {
            // console.log('EVENT_moveInDiorama', _id);
            this.desiredState[_id] = 2;

            this.dioramaSun.intensity = this.dioramas[_id].sunSettings.intensity;
            this.dioramaSun.diffuse = this.dioramas[_id].sunSettings.color;
            this.dioramaSun.direction = this.dioramas[_id].sunSettings.direction;

            this.m_mc20DioramaCar.position = this.dioramas[_id].impostor.position;
            this.m_mc20DioramaCar.rotation = this.dioramas[_id].impostor.rotation;
        });
        bus.$on('EVENT_moveOutDiorama', (_id: number) => {
            // console.log('EVENT_moveOutDiorama', _id);
            this.desiredState[_id] = 1;
        });
    }

    public update() {
        //check if a change is needed
        this.currentState.forEach((dioramaCurrentState, id) => {
            if (this.desiredState[id] !== dioramaCurrentState) {
                // console.log('Change is needed on diorama n°', id);
                if (!this.currentlyTransitioning) {
                    // Can start a new transition
                    if (this.desiredState[id] === 1) {
                        // If request is to hide a level, do it
                        this.launchTransition(id, false);
                        this.currentState[id] = 1; //TEMP, NEED TO DO A TRANSITION
                    } else if (this.desiredState[id] === 2) {
                        // If request is to show a level, do it only if no other level is visible
                        if (this.currentState.indexOf(2) === -1) {
                            this.launchTransition(id, true);
                            this.currentState[id] = 2; //TEMP, NEED TO DO A TRANSITION
                        } else {
                            // Can't show the level while another is already shown
                            // console.log("Can't show the level while another is already shown");
                        }
                    }
                } else {
                    // Can't start a new transition, need to wait for the end of the current transition
                    // console.log('Change needed but currently transitioning');
                }
            }
        });
    }

    private launchTransition(_id: number, _show: boolean) {
        this.currentlyTransitioning = true;
        this.windowFXs[_id].fadeIn(() => {
            // fade in ended
            this.setDioramaVisibility(_id, _show);
            const dioramaInfo = dioramas_data.find(dio => dio.id === _id);
            const meshWindow = this.scene.getMeshByName(dioramaInfo?.windowMesh as string);
            if (meshWindow) {
                meshWindow.isVisible = !_show;
            }
            // console.log('Fade in ' + _id + ' ended', _show);

            this.windowFXs[_id].fadeOut(() => {
                // fade out ended
                // console.log('Fade out ' + _id + ' ended', _show);
                this.currentlyTransitioning = false;
            });
        });
    }

    public loadDiorama(_id: number, showWhenReady: boolean) {
        const diorama = dioramas_data.find(el => el.id === _id);
        if (diorama) {
            console.log('Loading diorama : ', diorama);

            SceneLoader.LoadAssetContainer('./', diorama.gltfPath, this.scene, newContainer => {
                // When scene is loaded

                // Temporary fix to correct a weird 180° rotation on the mesh
                const rootMesh = newContainer.meshes[0];
                if (rootMesh.rotationQuaternion) {
                    rootMesh.rotationQuaternion.y = 0;
                }

                const skydome = newContainer.meshes.find(mesh => mesh.name === diorama.skydomeName);
                if (skydome !== undefined) {
                    skydome.applyFog = false;
                }

                diorama.initFunc(newContainer, this.scene);

                this.dioramas[_id].container = newContainer;
                if (showWhenReady) {
                    newContainer.addAllToScene();
                    this.currentState[_id] = 2;
                    this.desiredState[_id] = 2;
                } else {
                    this.currentState[_id] = 1;
                    this.desiredState[_id] = 1;
                }

                newContainer.removeAllFromScene();
            });
        } else {
            console.error("Didn't find the expected diorama");
        }
    }

    public requestVisibilityChange(_id: number, _visible: boolean) {}

    public setDioramaVisibility(_id: number, _visible: boolean) {
        if (this.dioramas[_id].container) {
            if (_visible) {
                const temp = this.dioramas[_id];

                this.scene.fogStart = temp.fogStartDistance;
                this.scene.fogEnd = temp.fogEndDistance;
                this.scene.fogColor = temp.fogColor;
                temp.container.addAllToScene();
            } else {
                this.dioramas[_id].container.removeAllFromScene();
            }
        } else {
            console.error("Didn't find the diorama with id : ", _id);
        }
    }

    private setupWindowFx() {
        dioramas_data.forEach(diorama => {
            this.windowFXs[diorama.id] = new WindowFX(this.scene.getMeshByName(diorama.windowFx) as Mesh);
        });
        console.log(this.windowFXs);
    }
}
