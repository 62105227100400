import { Engine } from '@babylonjs/core';

import AbstractScene from './AbstractScene';
import LoadingScreen from '@/components/LoadingScreen';
import { Transform } from '@/BabylonApp/Novelab/TransformType';

/**
 * Used to manage multiple scenes.
 */
export default class SceneManager {
    private static m_canvas: HTMLCanvasElement;
    private static m_engine: Engine;

    // Scenes collection
    private static m_scenes: Array<AbstractScene>;
    private static m_activeScene: AbstractScene;

    public static init(canvas: HTMLCanvasElement, engine: Engine) {
        this.m_canvas = canvas;
        this.m_engine = engine;
        this.m_scenes = new Array<AbstractScene>();
        this.m_engine.loadingScreen = new LoadingScreen();
    }

    public static addScene(scene: AbstractScene): void {
        this.m_scenes.push(scene);
    }

    public static removeScene(scene: AbstractScene): void {
        this.removeSceneByName(scene.name);
    }

    public static removeSceneByName(sceneName: string): void {
        for (let i = 0; i < this.m_scenes.length; i++) {
            if (sceneName === this.m_scenes[i].name) {
                const scene = this.m_scenes[i];
                if (scene.m_isReady) {
                    scene.disable();
                    scene.dispose();
                }

                this.m_scenes.splice(i, 1);
                return;
            }
        }
    }

    public static getScene(sceneName: string): AbstractScene {
        for (let i = 0; i < this.m_scenes.length; i++) {
            if (sceneName === this.m_scenes[i].name) {
                return this.m_scenes[i];
            }
        }

        return null as any;
    }

    public static get activeScene(): AbstractScene {
        return SceneManager.m_activeScene;
    }
    public static switchScene(sceneName: string, _destinationTransform?: Transform): boolean {
        const tmpScene = this.getScene(sceneName);
        if (tmpScene === null) {
            console.log('Scene ' + sceneName + ' does not exist');
            return false;
        }

        // this.m_engine.displayLoadingUI();
        this.m_engine.loadingScreen.displayLoadingUI();

        if (this.m_activeScene) {
            console.log('[SCENE MANAGER] Dispose scene: ' + this.m_activeScene.name);
            this.m_activeScene.disable();
            this.m_activeScene.dispose();
        }
        this.m_activeScene = tmpScene;
        if (_destinationTransform) {
            this.m_activeScene.init(
                this.m_canvas,
                this.m_engine,
                () => {
                    console.log('[SCENE MANAGER] Scene loaded: ' + this.m_activeScene.name);
                },
                _destinationTransform
            );
        } else {
            this.m_activeScene.init(this.m_canvas, this.m_engine, () => {
                console.log('[SCENE MANAGER] Scene loaded: ' + this.m_activeScene.name);
            });
        }

        return true;
    }

    public static runRenderLoop() {
        this.m_engine.runRenderLoop(() => {
            if (this.m_activeScene != null && this.m_activeScene.m_isReady) {
                this.m_activeScene.update();
                this.m_activeScene.render();
            }
        });
    }
}
