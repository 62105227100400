import { Engine } from '@babylonjs/core/Engines/engine';
import '@babylonjs/core/Debug/debugLayer';
import '@babylonjs/inspector';
import { bus } from '@/main';

import Mc20RoomScene from '@/BabylonApp/Scenes/Mc20RoomScene';
import HallScene from '@/BabylonApp/Scenes/HallScene';
import FragmentScene from '@/BabylonApp/Scenes/FragmentScene';
import SceneManager from './Novelab/SceneManager';
import levelsData from '@/utils/levels_data.json';
import { Transform } from '@/BabylonApp/Novelab/TransformType';

export default class Game {
    readonly canvas: HTMLCanvasElement;
    readonly engine: Engine;

    public constructor(canvas: HTMLCanvasElement) {
        this.canvas = canvas;
        this.engine = new Engine(this.canvas, false, { disableWebGL2Support: true });

        SceneManager.init(this.canvas, this.engine);

        SceneManager.addScene(new Mc20RoomScene('mc20Scene'));
        SceneManager.addScene(new HallScene('hallScene'));
        SceneManager.addScene(new FragmentScene('fragmentScene'));
        // SceneManager.switchScene('hallScene');
    }

    public init(): void {
        // Inspector
        window.addEventListener('keydown', ev => {
            // Press i to toggle babylon inspector

            if (ev.keyCode === 73) {
                if (SceneManager.activeScene.babylonScene.debugLayer.isVisible()) {
                    SceneManager.activeScene.babylonScene.debugLayer.hide();
                } else {
                    SceneManager.activeScene.babylonScene.debugLayer.show();
                }
            }
        });

        window.addEventListener('resize', () => {
            this.engine.resize();
        });

        window.addEventListener('orientationchange', () => {
            this.engine.resize();
        });

        // Check the url params and switch to a specific scene if valid argument
        const urlParams = new URLSearchParams(window.location.search);
        const requiredScene = urlParams.get('scene');
        if (requiredScene) {
            console.log('Required scene : ', requiredScene);
            if(!SceneManager.switchScene(requiredScene))
                SceneManager.switchScene('hallScene');
        }
        else
        {
            SceneManager.switchScene('hallScene');
        }

        SceneManager.runRenderLoop();
        this.setupChangeLevelEvents();
    }

    private setupChangeLevelEvents(): void {
        bus.$on('EVENT_doorTriggered', (_triggerId: number) => {
            const triggerMeshes: any[] = [];
            levelsData.forEach(lvlData => {
                if (lvlData.doorsTriggerMeshes.length > 0) {
                    triggerMeshes.push(...lvlData.doorsTriggerMeshes);
                }
            });

            const meshBeeingTriggerd = triggerMeshes.filter(trgMesh => trgMesh.id === _triggerId)[0];
            if (meshBeeingTriggerd) {
                const newLevel = levelsData.filter(lvl => lvl.id === meshBeeingTriggerd.destinationLevelId)[0];
                if (newLevel) {
                    const destinationTransform: Transform = {
                        position: meshBeeingTriggerd.destinationStartPosition,
                        rotation: meshBeeingTriggerd.destinationStartRotation,
                    };

                    SceneManager.switchScene(newLevel.name, destinationTransform);
                }
            }
        });

        // Keyboard shortcuts
        window.addEventListener('keydown', ev => {
            if (ev.key === 'k') {
                console.log('Switch level');
                SceneManager.switchScene('hallScene');
            }
            if (ev.key === 'l') {
                console.log('Switch level');
                SceneManager.switchScene('fragmentScene');
            }
            if (ev.key === 'm') {
                console.log('Switch level');
                SceneManager.switchScene('mc20Scene');
            }
        });
    }

    public getEngine(): Engine {
        return this.engine;
    }
}
