import { FreeCamera, Matrix, Nullable, Observer, Scene, Vector2, Vector3 } from '@babylonjs/core';
import PlayerController from '@/BabylonApp/PlayerController';
import { Engine } from '@babylonjs/core/Engines/engine';
import { AdvancedDynamicTexture, Control, Ellipse, Vector2WithInfo } from '@babylonjs/gui';
import { clamp } from '@/utils/MathFuncs';

class JoystickDescriptor {
    public outerEllipse!: Ellipse;
    public innerEllipse!: Ellipse;

    public isPressed = false;
    public startX!: number;
    public startY!: number;

    public pointerDownObserver!: Nullable<Observer<Vector2WithInfo>>;
    public pointerUpObserver!: Nullable<Observer<Vector2WithInfo>>;
    public pointerMoveObserver!: Nullable<Observer<Vector2>>;

    public appendToUI(uiTexture: AdvancedDynamicTexture): void {
        uiTexture.addControl(this.outerEllipse);
        this.outerEllipse.addControl(this.innerEllipse);
    }

    public removeFromUI(uiTexture: AdvancedDynamicTexture): void {
        uiTexture.removeControl(this.outerEllipse);
        this.outerEllipse.removeControl(this.innerEllipse);
    }
}

export default class VirtualJoystickControl {
    private readonly m_scene: Scene;
    private m_engine: Engine;

    private m_player: PlayerController;

    private xAddPos = 0;
    private yAddPos = 0;
    private xAddRot = 0;
    private yAddRot = 0;

    public rotationSpeed = 1;
    public movementSpeed = 1;
    public stickColor = '#ffffff';
    public stickAlphaInactive = 0.4;
    public stickRadius = 70;
    private stickInnerRadius = (this.stickRadius * 2) / 3;

    // UI elements
    private m_uiTexture!: AdvancedDynamicTexture;
    private m_leftStick!: JoystickDescriptor;
    private m_rightStick!: JoystickDescriptor;

    public constructor(scene: Scene, player: PlayerController) {
        this.m_scene = scene;
        this.m_engine = this.m_scene.getEngine();

        this.m_player = player;

        this.setupJoystick();
    }

    private setupJoystick(): void {
        this.m_uiTexture = AdvancedDynamicTexture.CreateFullscreenUI('joysticks_ui', true, this.m_scene);
        this.m_leftStick = new JoystickDescriptor();
        this.m_rightStick = new JoystickDescriptor();

        this.buildLeftStickVisuals();
        this.m_leftStick.appendToUI(this.m_uiTexture);
        this.setupLeftStickObservables();

        this.buildRightStickVisuals();
        this.m_rightStick.appendToUI(this.m_uiTexture);
        this.setupRightStickObservables();

        this.registerForRender();
    }

    private registerForRender(): void {
        const camRef: FreeCamera = this.m_player.camera;
        const translationCoef = this.movementSpeed / 20000;
        const rotationCoef = this.rotationSpeed / 60000;
        this.m_scene.registerBeforeRender(() => {
            //Update the camera position and rotation
            const translateTransform = Vector3.TransformCoordinates(
                new Vector3(this.xAddPos * translationCoef, 0, this.yAddPos * translationCoef),
                Matrix.RotationY(camRef.rotation.y)
            );
            camRef.cameraDirection.addInPlace(translateTransform);
            camRef.cameraRotation.y += this.xAddRot * rotationCoef * 1;
            camRef.cameraRotation.x += this.yAddRot * rotationCoef * -1;

            // Update sticks on screen position
            this.m_leftStick.innerEllipse.left = (this.xAddPos / 100) * this.stickRadius * 0.5;
            this.m_leftStick.innerEllipse.top = -1 * (this.yAddPos / 100) * this.stickRadius * 0.5;
            this.m_rightStick.innerEllipse.left = (this.xAddRot / 100) * this.stickRadius * 0.5;
            this.m_rightStick.innerEllipse.top = -1 * (this.yAddRot / 100) * this.stickRadius * 0.5;
        });
    }

    private buildLeftStickVisuals(): void {
        this.m_leftStick.outerEllipse = new Ellipse('leftStick_outer');

        // Outer Ellipse
        this.m_leftStick.outerEllipse.color = this.stickColor;
        this.m_leftStick.outerEllipse.alpha = this.stickAlphaInactive;
        this.m_leftStick.outerEllipse.thickness = 5.0;
        this.m_leftStick.outerEllipse.width = this.stickRadius * 2 + 'px';
        this.m_leftStick.outerEllipse.height = this.stickRadius * 2 + 'px';
        this.m_leftStick.outerEllipse.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        this.m_leftStick.outerEllipse.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
        this.m_leftStick.outerEllipse.top = -50.0;
        this.m_leftStick.outerEllipse.left = 50.0;
        this.m_leftStick.outerEllipse.isPointerBlocker = true;

        // Inner Ellipse
        this.m_leftStick.innerEllipse = new Ellipse('leftStick_inner');

        this.m_leftStick.innerEllipse.color = this.stickColor;
        this.m_leftStick.innerEllipse.background = this.stickColor;
        this.m_leftStick.innerEllipse.alpha = this.stickAlphaInactive;
        this.m_leftStick.innerEllipse.thickness = 5.0;
        this.m_leftStick.innerEllipse.width = this.stickInnerRadius * 2 + 'px';
        this.m_leftStick.innerEllipse.height = this.stickInnerRadius * 2 + 'px';
        this.m_leftStick.innerEllipse.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
        this.m_leftStick.innerEllipse.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
        this.m_leftStick.innerEllipse.isPointerBlocker = true;
    }

    private setupLeftStickObservables(): void {
        this.m_leftStick.pointerDownObserver = this.m_leftStick.outerEllipse.onPointerDownObservable.add(
            (pos: Vector2WithInfo) => {
                this.m_leftStick.isPressed = true;
                this.m_leftStick.innerEllipse.alpha = 1.0;
                this.m_leftStick.outerEllipse.alpha = 1.0;

                this.m_leftStick.startX = pos.x;
                this.m_leftStick.startY = pos.y;
            }
        );

        this.m_leftStick.pointerMoveObserver = this.m_leftStick.outerEllipse.onPointerMoveObservable.add((pos: Vector2) => {
            if (this.m_leftStick.isPressed) {
                const normalizedX = ((this.m_leftStick.startX - pos.x) * 100) / this.stickRadius;
                const normalizedY = ((this.m_leftStick.startY - pos.y) * 100) / this.stickRadius;
                this.xAddPos = clamp(-1 * normalizedX, -100, 100);
                this.yAddPos = clamp(1 * normalizedY, -100, 100);
                // console.log(this.xAddPos, this.yAddPos);
                // Return values clamped between -100 and 100, and relative to stick pixel size
            }
        });

        this.m_leftStick.pointerUpObserver = this.m_leftStick.outerEllipse.onPointerUpObservable.add((pos: Vector2WithInfo) => {
            this.m_leftStick.isPressed = false;
            this.m_leftStick.innerEllipse.alpha = this.stickAlphaInactive;
            this.m_leftStick.outerEllipse.alpha = this.stickAlphaInactive;
            this.xAddPos = 0;
            this.yAddPos = 0;
        });
    }

    private buildRightStickVisuals(): void {
        this.m_rightStick.outerEllipse = new Ellipse('leftStick_outer');

        // Outer Ellipse
        this.m_rightStick.outerEllipse.color = this.stickColor;
        this.m_rightStick.outerEllipse.alpha = this.stickAlphaInactive;
        this.m_rightStick.outerEllipse.thickness = 5.0;
        this.m_rightStick.outerEllipse.width = this.stickRadius * 2 + 'px';
        this.m_rightStick.outerEllipse.height = this.stickRadius * 2 + 'px';
        this.m_rightStick.outerEllipse.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
        this.m_rightStick.outerEllipse.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
        this.m_rightStick.outerEllipse.top = -50.0;
        this.m_rightStick.outerEllipse.left = -50.0;
        this.m_rightStick.outerEllipse.isPointerBlocker = true;

        // Inner Ellipse
        this.m_rightStick.innerEllipse = new Ellipse('leftStick_inner');

        this.m_rightStick.innerEllipse.color = this.stickColor;
        this.m_rightStick.innerEllipse.background = this.stickColor;
        this.m_rightStick.innerEllipse.alpha = this.stickAlphaInactive;
        this.m_rightStick.innerEllipse.thickness = 5.0;
        this.m_rightStick.innerEllipse.width = this.stickInnerRadius * 2 + 'px';
        this.m_rightStick.innerEllipse.height = this.stickInnerRadius * 2 + 'px';
        this.m_rightStick.innerEllipse.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
        this.m_rightStick.innerEllipse.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
        this.m_rightStick.innerEllipse.isPointerBlocker = true;
    }

    private setupRightStickObservables(): void {
        this.m_rightStick.pointerDownObserver = this.m_rightStick.outerEllipse.onPointerDownObservable.add(
            (pos: Vector2WithInfo) => {
                this.m_rightStick.isPressed = true;
                this.m_rightStick.innerEllipse.alpha = 1.0;
                this.m_rightStick.outerEllipse.alpha = 1.0;
                this.m_rightStick.startX = pos.x;
                this.m_rightStick.startY = pos.y;
            }
        );

        this.m_rightStick.pointerMoveObserver = this.m_rightStick.outerEllipse.onPointerMoveObservable.add((pos: Vector2) => {
            if (this.m_rightStick.isPressed) {
                const normalizedX = ((this.m_rightStick.startX - pos.x) * 100) / this.stickRadius;
                const normalizedY = ((this.m_rightStick.startY - pos.y) * 100) / this.stickRadius;
                this.xAddRot = clamp(-1 * normalizedX, -100, 100);
                this.yAddRot = clamp(1 * normalizedY, -100, 100);
                // console.log(this.xAddRot, this.yAddRot);
                // Return values clamped between -100 and 100, and relative to stick pixel size
            }
        });

        this.m_rightStick.pointerUpObserver = this.m_rightStick.outerEllipse.onPointerUpObservable.add((pos: Vector2WithInfo) => {
            this.m_rightStick.isPressed = false;
            this.m_rightStick.innerEllipse.alpha = this.stickAlphaInactive;
            this.m_rightStick.outerEllipse.alpha = this.stickAlphaInactive;

            this.xAddRot = 0;
            this.yAddRot = 0;
        });
    }
}
