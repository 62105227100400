






import { Component, Vue } from "vue-property-decorator";
import Game from "@/BabylonApp/Game";

@Component
export default class BabylonApp extends Vue {
    public game!: Game;

    mounted(): void {
        const canvas = document.getElementById(
            "renderCanvas"
        ) as HTMLCanvasElement;

        this.game = new Game(canvas);
        this.game.init();
    }
}
