import { Engine, Scene } from '@babylonjs/core';

import AbstractEntity from './AbstractEntity';
import Disposable from './Disposable';
import { Transform } from '@/BabylonApp/Novelab/TransformType';

/**
 * Abstract Scene
 *
 * Every Scene must extends this class and all the abstract methods.
 * This class offer :
 * - A basic Entity management (Logic)
 * - Global update loop management for scene
 */
export default abstract class AbstractScene implements Disposable {
    // Global information about the scene
    protected m_name: string;
    public get name(): string {
        return this.m_name;
    }

    protected m_scene!: Scene;
    public get babylonScene(): Scene {
        return this.m_scene;
    }

    // Scene's entities
    protected m_entities!: Array<AbstractEntity>;

    public constructor(name: string) {
        this.m_name = name;
    }

    m_isReady!: boolean;
    m_isEnabled!: boolean;

    public abstract init(_canvas: HTMLCanvasElement, _engine: Engine, _onSuccess: Function, _startTransform?: Transform): void;
    public abstract update(): void;
    public abstract render(): void;

    protected preInit(): void {
        this.m_entities = new Array<AbstractEntity>();
    }

    protected addEntity(entity: AbstractEntity): void {
        entity.init();
        this.m_entities.push(entity);
    }

    protected removeEntity(entity: AbstractEntity): void {
        this.removeEntityByName(entity.name);
    }

    protected removeEntityByName(entityName: string): void {
        for (let i = 0; i < this.m_entities.length; i++) {
            if (entityName === this.m_entities[i].name) {
                this.m_entities.splice(i, 1);
                return;
            }
        }
    }

    protected removeAllEntities(): void {
        if (this.m_entities) {
            this.m_entities.forEach(entity => entity.dispose());
            this.m_entities = new Array<AbstractEntity>();
        }
    }

    protected getEntityByName(entityName: string): AbstractEntity | null {
        for (let i = 0; i < this.m_entities.length; i++) {
            if (entityName === this.m_entities[i].name) {
                return this.m_entities[i];
            }
        }

        return null;
    }

    public enable(): void {
        this.m_isEnabled = true;
    }

    public disable(): void {
        this.m_isEnabled = false;
    }

    public dispose() {
        this.removeAllEntities();
    }
}
