<template>
    <div v-if="!errorAPI" class="panel-container">
        <div class="panel-content">
            <div class="panel-header">
                <h2>{{ this.loaded ? this.productData.title : 'Loading...' }}</h2>
                <p v-if="this.loaded && shouldDisplayPrice && !usingJsonData">{{ formatedPrice }} <span>VAT included</span></p>
                <p v-if="this.loaded && shouldDisplayPrice && usingJsonData">
                    {{ this.productData.price }} <span>VAT included</span>
                </p>
            </div>
            <div class="panel-body">
                <h2 v-if="this.loaded">Description</h2>
                <div class="text-content" v-if="this.loaded" v-html="formatedDescription"></div>
            </div>
            <div class="panel-footer">
                <a v-if="productData.link !== undefined && productData.link.length > 0" class="btn" target="_blank" :href="productData.link">See more</a>
                <div v-if="productData.link === undefined || productData.link.length === 0" class="btn">Not available now</div>
            </div>
        </div>
    </div>
</template>
<script>
import { fetchProductById } from '@/utils/API';
import { allproductsJsonData } from '@/utils/products_data';

export default {
    props: {
        productId: Number,
    },

    data() {
        return {
            loaded: false,
            productData: {},
            errorAPI: false,
            usingJsonData: false,
        };
    },
    computed: {
        shouldDisplayPrice: function() {
            if (!this.productData.price) {
                return false;
            }
            const price = parseInt(this.productData.price.value);
            if (price <= 0) {
                // When a price is not defined, the api returns 000.0000EUR
                return false;
            }
            return true;
        },
        formatedDescription: function() {
            const splitted = this.productData.description.split('\n');
            const formatedText = splitted.map(line => '<p>' + line + '</p>');
            return formatedText.join('');
        },
        formatedPrice: function() {
            let val = parseInt(this.productData.price.value);
            val = val.toFixed(2).replace(/[.,]00$/, '');

            let cur = this.productData.price.currency;
            cur = cur.replace(/EUR/, '€');

            const string = val + cur;
            return string;
        },
    },

    mounted() {
        this.loaded = false;
        this.errorAPI = false;
        this.usingJsonData = false;
        fetchProductById(this.productId)
            .then(resp => {
                if (resp) {
                    this.productData = resp;

                    const jsonData = this.getJsonProductData();
                    if (jsonData) {
                        this.productData.link = jsonData.link;
                    } else {
                        console.warn('No data json data found either');
                    }
                } else {
                    console.log('No resp from the API, trying to use json data');
                    const jsonData = this.getJsonProductData();
                    if (jsonData) {
                        this.productData.title = jsonData.title;
                        this.productData.price = jsonData.price;
                        this.productData.description = jsonData.description;
                        this.productData.link = jsonData.link;
                        this.usingJsonData = true;
                    } else {
                        console.warn('No data json data found either');
                    }
                }

                console.log('API Resp : ', resp);
            })
            .catch(error => {
                console.error(error);
                this.errorAPI = true;
            })
            .finally(() => {
                this.loaded = true;
            });
    },

    methods: {
        getJsonProductData: function() {
            const prd = allproductsJsonData.find(el => el.product_number === this.productId);
            return prd;
        },
    },
};
</script>
<style lang="scss">
@import '@/style/_variables.scss';
@import '@/style/_mediaQueries.scss';
@import '@/style/_mixins.scss';

$padding: 20px;

.panel-container {
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 100;
    height: 100%;
    width: 33%;
    min-width: 300px;
    max-width: 500px;
    background-color: $secondary-color;
    opacity: 0.9;
    @include mq(medium) {
        // background-color: red;
    }

    .panel-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .panel-header {
            background-color: $primary-color;
            padding: $padding;
            height: 20%;

            h2 {
                font-weight: 300;
                margin-bottom: 0.5rem;
                font-size: 1.1rem;
                color: #fff;
                margin-right: 30px; //space for the close-button
                @include mq(medium) {
                    font-size: 1.4rem;
                }
                @include mq(large) {
                    font-size: 1.8rem;
                }
            }
            p {
                color: #fff;
                font-weight: 300;
                font-size: 0.9rem;
                @include mq(medium) {
                    font-size: 1.1rem;
                }
                @include mq(large) {
                    font-size: 1.3rem;
                }
                span {
                    padding-left: 0.7em;
                    font-size: 0.8rem;
                    color: #c4c4c4;
                    @include mq(medium) {
                        font-size: 1rem;
                    }
                    @include mq(large) {
                        font-size: 1.3rem;
                    }
                }
            }
        }

        .panel-body {
            background-color: $secondary-color;
            height: 60%;
            padding: $padding;
            h2 {
                font-weight: 400;
                // margin-bottom: 0.5em;
                height: 40px;
                font-size: 1.5rem;
            }
            .text-content {
                overflow-y: scroll;
                height: calc(100% - 40px);
                @include scrollbars(10px, white, #c4c4c4);
                p {
                    color: #6d6d6d;
                    font-weight: 400;
                    font-size: 0.8rem;
                    padding-bottom: 1rem;
                    overflow: none;
                    @include mq(medium) {
                        font-size: 0.9rem;
                    }
                    @include mq(large) {
                        font-size: 1rem;
                    }
                }
            }
        }

        .panel-footer {
            background-color: $primary-color;
            padding: $padding;
            height: 20%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .btn {
                display: flex;
                justify-content: center;
                align-items: center;

                cursor: pointer;
                text-decoration: none;
                font-weight: 400;
                background-color: $secondary-color;
                color: $primary-color;
                width: 100%;
                min-height: 50px;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
                border-radius: 3px;
                margin-bottom: 10px;

                &:hover
                {
                    background-color: $third-color;
                }
            }
        }
    }
}
</style>
