import {
    AbstractMesh,
    AssetsManager,
    Color3,
    CubeTexture,
    DefaultRenderingPipeline,
    Engine,
    HemisphericLight,
    Mesh,
    PBRMaterial,
    RenderTargetTexture,
    Scene,
    SceneLoader,
    ShaderMaterial,
    Sprite,
    SpriteManager,
    SpriteMap,
    StandardMaterial,
    Texture,
    Vector2,
    Vector3,
} from '@babylonjs/core';
import AbstractScene from '@/BabylonApp/Novelab/AbstractScene';
import PlayerController from '@/BabylonApp/PlayerController';
import URLHelper from '@/utils/URL';
import ProductAnimationBehavior from '@/BabylonApp/Behaviors/ProductAnimationBehavior';
import { addTriggerWindowBehavior } from '@/BabylonApp/Behaviors/TriggerWindowBehavior';
import { addPickableBehavior } from '../Behaviors/PickableProductBehavior';
import VirtualJoystickControl from '@/BabylonApp/Novelab/VirtualJoystickControl';
import { bus } from '../../main';
import MC20LogoAnimationBehavior from '@/BabylonApp/Behaviors/MC20LogoAnimationBehavior';
import DioramaHandler from '@/BabylonApp/Scenes/mc20RoomScene/DioramaHandler';
import WindowFX from '@/BabylonApp/Scenes/mc20RoomScene/WindowFX';
import dioramas_data from '@/utils/dioramas_data';
import { mc20ProductsJsonData } from '@/utils/products_data';
import { addTriggerDoorBehavior } from '@/BabylonApp/Behaviors/TriggerDoorBehavior';
import levelsData from '@/utils/levels_data.json';
import { Transform } from '@/BabylonApp/Novelab/TransformType';

export default class Mc20RoomScene extends AbstractScene {
    private levelId = 2;
    private levelData = levelsData[this.levelId - 1];

    private m_sceneDirectoryPath = './assets/scenes/02_mc20Room/';
    private m_sceneFilePath = 'MC20_Room.babylon';

    private m_player!: PlayerController;
    private renderPipeline!: DefaultRenderingPipeline;
    private m_renderTarget!: RenderTargetTexture;

    private frosGlassShaderMat!: ShaderMaterial;
    private lightPathShaderMat!: ShaderMaterial;

    private joysticks!: VirtualJoystickControl;

    private totalTime!: number;
    public get totalTimeElapsed() {
        return this.totalTime;
    }

    private m_pickableMeshes: AbstractMesh[] = [];
    private dioramaHandler!: DioramaHandler;

    private m_windowFXs: WindowFX[] = [];

    init(_canvas: HTMLCanvasElement, _engine: Engine, _onSuccess: Function, _startTransform?: Transform): void {
        SceneLoader.Load(this.m_sceneDirectoryPath, this.m_sceneFilePath, _engine, (scene: Scene) => {
            this.m_scene = scene;

            this.m_player = new PlayerController(this.m_scene);

            const spawnTransform = this.getSpawnTransform(_startTransform);
            this.m_player.camera.position = new Vector3(
                spawnTransform.position.x,
                spawnTransform.position.y,
                spawnTransform.position.z
            );
            this.m_player.camera.rotation = new Vector3(
                spawnTransform.rotation.x,
                spawnTransform.rotation.y,
                spawnTransform.rotation.z
            );

            this.setupEnvironment();
            this.setupRenderPipeline();
            this.setupMaterialsTweaks();

            // GrabPass for frosted Glass Effect
            this.setupRenderTargetTexture();
            this.setupFrostedGlassShader();
            this.setupLightPathShader();

            this.setupColliderMeshes();
            this.attachControlsBasedOnDevice();
            this.setupDioramaHandler();
            this.setupTriggers();
            this.setupBehaviors();

            this.setupEngineSprite();

            this.enable();
            this.m_isReady = true;
            _onSuccess();
        });
    }

    render(): void {
        this.m_scene.render();
    }

    update(): void {
        if (this.m_isReady) {
            const canvas = this.m_scene.getEngine().getRenderingCanvas();
            const screenSize = new Vector2(canvas?.width, canvas?.height);
            this.frosGlassShaderMat.setVector2('screenSize', screenSize);

            this.lightPathShaderMat.setFloat('time', this.totalTime);
            this.totalTime += this.m_scene.getEngine().getDeltaTime();
        }
        this.dioramaHandler.update();
    }

    private getSpawnTransform(_startTransform?: Transform): Transform {
        if (_startTransform) {
            //When the player come from another room
            return _startTransform;
        } else {
            // When the player spawn for the first time
            const defaultSpawn: Transform = {
                position: { x: 0, y: 2, z: 0 },
                rotation: { x: 0, y: 0, z: 0 },
            };
            return defaultSpawn;
        }
    }

    public dispose(): void {
        super.dispose();
        this.m_scene.dispose();
    }

    private attachControlsBasedOnDevice(): void {
        this.m_player.attachControls();
        this.joysticks = new VirtualJoystickControl(this.m_scene, this.m_player);

        // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        //     console.log('mobile device');
        //     this.joysticks = new VirtualJoystickControl(this.m_scene, this.m_player);
        // } else {
        //     console.log('not mobile device');
        //     this.m_player.attachControls();
        // }
    }

    private setupLightPathShader(): void {
        this.totalTime = 0.0;

        this.lightPathShaderMat = new ShaderMaterial('lightPathShaderMat', this.m_scene, './assets/shaders/lightPath', {
            attributes: ['position', 'uv'],
            uniforms: ['worldViewProjection', 'time'],
        });

        const railway = this.m_scene.getMeshByName('RailWay') as Mesh;
        railway.material = this.lightPathShaderMat;
    }

    public setupFrostedGlassShader(): void {
        // Dissolve Shader material
        console.log('Init shader');
        this.frosGlassShaderMat = new ShaderMaterial('frostedGlassShaderMat', this.m_scene, './assets/shaders/frostedglass', {
            attributes: ['position', 'uv'],
            uniforms: ['worldViewProjection'],
        });
        this.frosGlassShaderMat.transparencyMode = 1;

        // Set walls and floor mat unlit
        const mc20_a1Mat = this.m_scene.getMaterialByName('mc20_A1') as PBRMaterial;
        const mc20_a2Mat = this.m_scene.getMaterialByName('mc20_A2') as PBRMaterial;

        mc20_a1Mat.unlit = true;
        mc20_a2Mat.unlit = true;

        const noiseTex = new Texture('./assets//scenes/02_mc20Room/textures/Noises/noise.jpg', this.m_scene);
        const distortionTex = new Texture('./assets//scenes/02_mc20Room/textures/Noises/distortion_02.jpg', this.m_scene);
        this.frosGlassShaderMat.setTexture('textureSampler', this.m_renderTarget);
        this.frosGlassShaderMat.setTexture('noiseTex', noiseTex);
        this.frosGlassShaderMat.setTexture('distortionTex', distortionTex);

        const door01 = this.m_scene.getMeshByName('ENV_Window_01') as Mesh;
        door01.material = this.frosGlassShaderMat;

        const door02 = this.m_scene.getMeshByName('ENV_Window_02') as Mesh;
        door02.material = this.frosGlassShaderMat;

        const door03 = this.m_scene.getMeshByName('ENV_Window_03') as Mesh;
        door03.material = this.frosGlassShaderMat;

        const door04 = this.m_scene.getMeshByName('ENV_Window_04') as Mesh;
        door04.material = this.frosGlassShaderMat;
    }

    private setupEnvironment() {
        const sky = CubeTexture.CreateFromPrefilteredData(
            URLHelper.get('/assets/scenes/02_mc20Room/skybox/MoutainSkies.env'),
            this.m_scene
        );

        this.m_scene.environmentTexture = sky;
        this.m_scene.environmentIntensity = 1;
        const skybox = this.m_scene.createDefaultSkybox(sky, false, 500, 0, false);

        if (skybox !== null) {
            skybox.rotate(Vector3.Up(), 90);
            skybox.applyFog = false;

            const mat = skybox.material as StandardMaterial;
            mat.disableLighting = true;
        }

        this.m_scene.fogEnabled = true;
        this.m_scene.fogColor = Color3.White();
        this.m_scene.fogMode = 3;
        this.m_scene.fogStart = 30;
        this.m_scene.fogEnd = 150;

        const hemilight = new HemisphericLight('Ambiant', Vector3.Down(), this.m_scene);
        hemilight.intensity = 0.002;
        hemilight.groundColor = new Color3();
        hemilight.diffuse = new Color3(123, 137, 113);
    }

    private setupRenderPipeline(): void {
        // Graphic Pipeline setup
        this.renderPipeline = new DefaultRenderingPipeline('defaultPipeline', false, this.m_scene, [this.m_player.camera]);

        // Fxaa (Anti Aliasing)
        this.renderPipeline.fxaaEnabled = true;
        this.renderPipeline.fxaa.samples = 4;

        // Bloom Post Process
        this.renderPipeline.bloomEnabled = true;
        this.renderPipeline.bloomThreshold = 0.9;
        this.renderPipeline.bloomWeight = 0.15;
        this.renderPipeline.bloomKernel = 64;
        this.renderPipeline.bloomScale = 0.5;

        // Tone Mapping
        this.renderPipeline.imageProcessing.toneMappingEnabled = true;
    }

    private setupMaterialsTweaks(): void {
        /*
         * Room
         */
        const led_mat = this.m_scene.getMaterialByName('LED_mat') as PBRMaterial;
        led_mat.emissiveColor = Color3.White();
        led_mat.emissiveIntensity = 2;

        const gradientDoor_mat = this.m_scene.getMaterialByName('Gradient_door_mat') as PBRMaterial;
        gradientDoor_mat.emissiveColor = Color3.White();
        gradientDoor_mat.emissiveIntensity = 1;

        const starSkyboxMat = this.m_scene.getMaterialByName('StarSkyDome_mat') as PBRMaterial;
        starSkyboxMat.unlit = true;
        starSkyboxMat.specularIntensity = 0.0;
        starSkyboxMat.albedoColor = new Color3(0.62, 0.62, 0.62);

        /*
         * Car
         */
        const glassMat = this.m_scene.getMaterialByID('MC20_glass') as PBRMaterial;
        glassMat.transparencyMode = 2;
        glassMat.alpha = 0.2;
        glassMat.environmentIntensity = 0.2;

        const tintGlassMat = this.m_scene.getMaterialByID('MC20_tint_glass') as PBRMaterial;
        tintGlassMat.transparencyMode = 2;
        tintGlassMat.alpha = 0.6;
        tintGlassMat.environmentIntensity = 0.2;

        const redGlassMat = this.m_scene.getMaterialByID('MC20_red_glass') as PBRMaterial;
        redGlassMat.emissiveColor = new Color3(1, 0, 0);
        redGlassMat.emissiveIntensity = 10.0;
        redGlassMat.environmentIntensity = 0;

        const carAtlasMat = this.m_scene.getMaterialByName('MC20_Atlas') as PBRMaterial;
        carAtlasMat.environmentIntensity = 0;

        const carBodyMat = this.m_scene.getMaterialByName('MC20_body') as PBRMaterial;
        carBodyMat.environmentIntensity = 0.2;

        const carAtlas01Mat = this.m_scene.getMaterialByName('MC20_CarAtlas_01') as PBRMaterial;
        carAtlas01Mat.environmentIntensity = 0;

        const carAtlas02Mat = this.m_scene.getMaterialByName('MC20_CarAtlas_02') as PBRMaterial;
        carAtlas02Mat.environmentIntensity = 0;

        // const honeyGridMat = this.m_scene.getMaterialByID('MC20_honeygrid') as PBRMaterial;
        // honeyGridMat.transparencyMode = 2;

        /**
         * Posters
         */
        const postersMat = this.m_scene.getMaterialByName('MC20_Posters_mat') as PBRMaterial;
        postersMat.unlit = true;
    }

    private setupRenderTargetTexture(): void {
        this.m_renderTarget = new RenderTargetTexture('depth', 1024, this.m_scene, true);
        this.m_scene.customRenderTargets.push(this.m_renderTarget);

        if (this.m_renderTarget.renderList != null) {
            const lrsSaltDesert = this.m_scene.getMeshByName('03_LowResSphere_Saltlake') as Mesh;
            const lrsRockDesert = this.m_scene.getMeshByName('02_LowResSphere_Desert') as Mesh;
            const lrsRoad = this.m_scene.getMeshByName('04_LowResSphere_Racetrack') as Mesh;
            const lrsCastle = this.m_scene.getMeshByName('01_LowResSphere_Castle') as Mesh;

            (lrsSaltDesert.material as PBRMaterial).unlit = true;
            lrsSaltDesert.isVisible = false;
            (lrsRockDesert.material as PBRMaterial).unlit = true;
            lrsRockDesert.isVisible = false;
            (lrsRoad.material as PBRMaterial).unlit = true;
            lrsRoad.isVisible = false;
            (lrsCastle.material as PBRMaterial).unlit = true;
            lrsCastle.isVisible = false;

            this.m_renderTarget.renderList.push(lrsSaltDesert);
            this.m_renderTarget.renderList.push(lrsRockDesert);
            this.m_renderTarget.renderList.push(lrsRoad);
            this.m_renderTarget.renderList.push(lrsCastle);

            this.m_renderTarget.onBeforeRenderObservable.add(() => {
                lrsSaltDesert.isVisible = true;
                lrsRockDesert.isVisible = true;
                lrsRoad.isVisible = true;
                lrsCastle.isVisible = true;
            });

            this.m_renderTarget.onAfterRenderObservable.add(() => {
                lrsSaltDesert.isVisible = false;
                lrsRockDesert.isVisible = false;
                lrsRoad.isVisible = false;
                lrsCastle.isVisible = false;
            });
        }
    }

    private setupColliderMeshes(): void {
        this.m_scene.getMeshesByTags('Collider', amesh => {
            const mesh = amesh as Mesh;

            mesh.checkCollisions = true;
            if (mesh.name !== 'Ground') {
                //TODO : Change that
                mesh.isVisible = false;
            }
        });
    }

    private setupBehaviors(): void {
        // Re-enable all the hidden meshes (when product panel is closed)
        bus.$on('EVENT_closeProductOverlay', () => {
            this.m_pickableMeshes.forEach(mesh => {
                mesh.setEnabled(true);
            });
        });

        // find all the product meshes and add them the necessary behaviors
        mc20ProductsJsonData.forEach(prd => {
            const mesh = this.m_scene.getMeshByName(prd.mesh_scene_name);
            if (mesh) {
                this.m_pickableMeshes.push(mesh);
                addPickableBehavior(mesh);

                //Add the rotation behavior
                if (mesh.name === 'mc20_model_1_8' || mesh.name === 'MC20_tshirt') {
                    mesh.addBehavior(new ProductAnimationBehavior());
                }
            }
        });

        // Logo animation behavior
        const mc20Logo = this.m_scene.getMeshByName('MC20_Logo') as Mesh;
        if (mc20Logo !== null) mc20Logo.addBehavior(new MC20LogoAnimationBehavior());
    }

    private setupDioramaHandler(): void {
        this.dioramaHandler = new DioramaHandler(this.m_scene);

        this.dioramaHandler.loadDiorama(1, false); // castle
        this.dioramaHandler.loadDiorama(2, false); // desert
        this.dioramaHandler.loadDiorama(3, false); // salt desert
        this.dioramaHandler.loadDiorama(4, false); // racetrack
    }

    private setupTriggers(): void {
        // Setup the triggers for the doors
        const triggerMeshes = this.levelData.doorsTriggerMeshes;
        if (!triggerMeshes) {
            console.log('No doors to setup trigger for');
            return;
        }
        triggerMeshes.forEach(trgMeshData => {
            //find all the collider meshes of the doors to add them a behavior
            const trgMesh = this.m_scene.getMeshByName(trgMeshData.triggerMeshName) as Mesh;
            addTriggerDoorBehavior(trgMesh, this.m_player.playerColliderMesh, trgMeshData.id);
        });

        // Get the window trigger mesh from the scene
        const windowTriggers: Mesh[] = [];
        dioramas_data.forEach(diorama => {
            windowTriggers[diorama.id] = this.m_scene.getMeshByName(diorama.triggerMeshName) as Mesh;
        });

        // Add the window mesh a colliding behavior
        windowTriggers.forEach(trigger => {
            trigger.isVisible = false;
            addTriggerWindowBehavior(trigger, this.m_player.playerColliderMesh);
        });
        this.dioramaHandler.windowTriggers = windowTriggers;
    }

    private setupEngineSprite(): void {
        // Load the spritesheet (with appropriate settings) associated with the JSON Atlas.
        const spriteSheet = new Texture(
            URLHelper.get('./assets/scenes/02_mc20Room/textures/baking_mc20_a2.png'),
            this.m_scene,
            false, //NoMipMaps
            false, //InvertY usually false if exported from TexturePacker
            Texture.LINEAR_LINEAR, //Sampling Mode
            null, //Onload, you could spin up the sprite map in a function nested here
            null, //OnError
            null, //CustomBuffer
            false, //DeleteBuffer
            Engine.TEXTUREFORMAT_RGBA //ImageFormageType RGBA
        );

        const spriteManager = new SpriteManager(
            'spriteManager',
            URLHelper.get('./assets/scenes/02_mc20Room/Diorama/02_Desert/Engine/SpriteSheet.png'),
            2,
            512,
            this.m_scene
        );
        const sprite = new Sprite('engine', spriteManager);
        sprite.position = new Vector3(8.8, 2, 16);
        sprite.size = 5.0;
        sprite.playAnimation(0, 23, true, 4000.0 / 24.0);
    }
}
